










































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { AppModule } from '@/store/app/AppModule';
import { Context } from 'vuex-smart-module';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import { NotificationsService } from '@/services/NotificationService';

const requireField = (val: string) => !!val || 'Обязательное поле';

@Component({
  components: { },
})
export default class ChangePassword extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() notify!: NotificationsService;

  passwordForm = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  rules = {
    password: [
      requireField,
    ],
  };

  @Prop({
    required: true,
  })
  user!: User;

  async changePassword() {
    if (this.passwordForm.currentPassword
      && this.passwordForm.newPassword
      && this.passwordForm.confirmPassword
      && this.passwordForm.newPassword === this.passwordForm.confirmPassword) {
      try {
        await this.apiFacade.updatePassword(this.user, this.passwordForm);
        await this.notify.addNotification({ type: 'success', text: 'Профиль успешно обновлён' });
      } catch (e) {
        // TODO Добавить вывод ошибки с сервера
        await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
      }
    } else {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка! Пароли не указаны или введённые пароли не совпадают!' });
    }
  }
}
