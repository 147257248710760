









import { Component, Inject, Vue } from 'vue-property-decorator';
import UserMainCard from '@/views/profile/components/UserMainCard.vue';
import ChangePassword from '@/views/profile/components/ChangePassword.vue';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';

@Component({
  components: {
    UserMainCard,
    ChangePassword,
  },
})
export default class ProfileView extends Vue {
  @Inject() appStore!: Context<AppModule>;

  get profile() {
    return this.appStore.getters.profile;
  }
}
