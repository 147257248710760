






































import {
  Component,
  Emit,
  Inject,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import {
  UserPolygonRoles, UserRoleIds, UserRoleIdsIri, UserRoleIriIds, UserRoleIriNames,
} from '@/types/UserRoleIds';
import { UserRolePolygonType } from '@/types/UserRolePolygonType';

type UserRolePolygonLocal = Record<PolygonIriIdentifier, UserRoleIdsIri | 'none'>;

@Component({})
export default class UserRights extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Prop({ required: true })
  userRolePolygons!: UserRolePolygonType[];

  userRolePolygonsLocal: UserRolePolygonLocal = {};

  isSuperAdminSwitch: boolean = false;

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }

  get isAdmin() {
    return this.appStore.getters.profile?.isAdmin;
  }

  get isTeacher() {
    return this.appStore.getters.profile?.isTeacher;
  }

  get polygons() {
    return this.appStore.getters?.availablePolygons || [];
  }

  get getUserPolygonRoles(): Partial<Record<UserPolygonRoles, string>> {
    const roles: Partial<Record<UserPolygonRoles, string>> = {
      none: 'Нет доступа',
      [UserRoleIdsIri.student]: UserRoleIriNames[UserRoleIdsIri.student],
    };

    if (this.isTeacher) {
      roles[UserRoleIdsIri.teacher] = UserRoleIriNames[UserRoleIdsIri.teacher];
    }

    if (this.isAdmin) {
      roles[UserRoleIdsIri.admin] = UserRoleIriNames[UserRoleIdsIri.admin];
    }

    return roles;
  }

  mounted() {
    this.updateLocals();
  }

  updateLocals() {
    const newUserRolePolygonsLocal: UserRolePolygonLocal = {};

    this.polygons.forEach((p) => {
      newUserRolePolygonsLocal[p['@id']] = 'none';
    });

    this.userRolePolygons.forEach((urp) => {
      if (urp.polygon && urp.polygon['@id'] && urp.userRole['@id']) {
        newUserRolePolygonsLocal[urp.polygon['@id']] = urp.userRole['@id'];
      } else if (urp.userRole['@id'] === UserRoleIdsIri.superadmin) {
        this.isSuperAdminSwitch = true;
      }
    });

    this.userRolePolygonsLocal = newUserRolePolygonsLocal;
  }

  @Emit('input')
  userRolePolygonsLocalWatcher() {
    const updatedUrp: UserRolePolygonType[] = [];

    if (this.isSuperAdminSwitch) {
      updatedUrp.push({
        userRole: { '@id': UserRoleIdsIri.superadmin, userRoleId: UserRoleIds.superadmin },
      });
      return updatedUrp;
    }

    const keys = Object.keys(this.userRolePolygonsLocal);
    keys.forEach((k) => {
      const roleIriId = this.userRolePolygonsLocal[k];
      if (roleIriId !== 'none') {
        updatedUrp.push({
          userRole: {
            '@id': roleIriId,
            userRoleId: UserRoleIriIds[roleIriId],
          },
          polygon: this.polygons.find((p) => p['@id'] === k),
        });
      }
    });

    return updatedUrp;
  }
}
