

















































































































































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { AppModule } from '@/store/app/AppModule';
import { Context } from 'vuex-smart-module';
import { User } from '@/models/entities/User';
import UserAvatar from '@/views/profile/components/UserAvatar.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { VFormInput } from '@/types/VForm';
import { NotificationsService } from '@/services/NotificationService';
import UserRights from '@/views/users/components/UserRights.vue';

const requireField = (val: string) => !!val || 'Обязательное поле';

interface UserMainCard {
  $refs: {
    avatarFile: VFormInput;
  };
}

@Component({
  components: {
    UserAvatar,
    UserRights,
  },
})
class UserMainCard extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() notify!: NotificationsService;

  @Prop({
    required: true,
  })
  user!: User;

  @Prop({})
  isUserProfile: boolean = false;

  form: User = User.createFromDto({});
  avatarFile: null = null;
  isDeleteProfileDialogVisible: boolean = false;

  rules = {
    firstName: [
      requireField,
    ],
  };

  mounted() {
    Object.assign(this.form, this.user);
  }

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }

  get isAdmin() {
    return this.appStore.getters.profile?.isAdmin;
  }

  get showReActivation() {
    return (this.isSuperAdmin || this.isAdmin)
      && !this.isUserProfile
      && this.form.status === 'registered';
  }

  async uploadAvatar(selectedFile: File) {
    if (!this.avatarFile) {
      return;
    }

    try {
      await this.appStore.actions.uploadAvatar({ user: this.form, file: selectedFile });
      await this.notify.addNotification({ type: 'success', text: 'Аватар успешно обновлён' });
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Произошла ошибка' });
    } finally {
      this.avatarFile = null;
      this.$refs.avatarFile.blur();
    }
  }

  async updateUser() {
    if (this.isUserProfile) {
      await this.updateSelfProfile();
    } else {
      await this.updateUserProfile();
    }
  }

  async resendActivation() {
    try {
      await this.apiFacade.postResendActivation(this.form);
      await this.notify.addNotification({ type: 'success', text: 'Повторное письмо приглашение отправлено' });
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
    }
  }

  async updateSelfProfile() {
    try {
      await this.appStore.actions.updateProfile(this.form);
      await this.notify.addNotification({ type: 'success', text: 'Ваш профиль успешно обновлён' });
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
    }
  }

  async updateUserProfile() {
    try {
      await this.apiFacade.updateUser(this.form);
      await this.notify.addNotification({ type: 'success', text: 'Профиль пользователя успешно обновлён' });
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
    }
  }

  async confirmDeleteProfile() {
    await this.apiFacade.deleteUser(this.form);
    if (this.$route.name === 'profile') {
      window.location.href = '/api/v1/logout';
    } else { // Предполагаем что иначе мы в админке юзверей
      await this.notify.addNotification({ type: 'success', text: 'Пользователь удалён' });
      this.$router.push({ name: 'admin-users' });
    }
  }
}

export default UserMainCard;
